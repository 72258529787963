import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useInView } from 'react-hook-inview'
import { motion } from 'framer-motion'

const StyledCases = styled.div``

const Post = styled(motion.div)``

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const ImgContainer = styled.div`
  height: 200px;
  width: 300px;
`

const PostContent = styled(motion.div)`
  width: 300px;
  border-radius: 20px;
  padding: 30px;
`

const Description = styled.div`
  min-height: 90px;

  p {
    font-size: ${props => props.theme.font.size.s};
  }
`

const PostWrapper = styled.div`
  position: relative;
  top: -20px;
`

const InnerPost = styled.div``

const AllVacatures = ({ className }) => {
  const {
    vacatures: {
      nodes: vacatures
    }
  } = useStaticQuery(graphql`{
    vacatures: allWordpressWpVacatures(sort: {fields: date, order: DESC}) {
      nodes {
        ...PreviewVacaturesFrag
      }
    }
  }`)

  const [ref, isVisible] = useInView({
    threshold: 0.3,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledCases ref={ref} className={`container ${className ? `${className}` : ``}`}>
      <div ref={ref} className="row py-lg-5">
        {vacatures.map((singleCase, index) => (
          <Post
            key={singleCase.wordpress_id} 
            className="col-lg-4 pb-5 d-flex justify-content-center"
            whileHover="hover"
            initial="rest"
            animate={isVisible ? "init" : "rest"}
            variants={{
              hover: {},
              rest: {
                opacity: 0.4, y: 30
              },
              init: {
                opacity: 1, y: 0,
                transition: { delay: index * 0.2, type: 'spring', duration: .8 }
              }
            }}
          >
            <InnerPost>
              <div className="d-flex justify-content-center">
                <ImgContainer>
                  <StyledImg loading="eager" fadeIn={false} fluid={singleCase.acf.preview.image.localFile.childImageSharp.fluid} alt="" />
                </ImgContainer>
              </div>
              <PostWrapper className="d-flex justify-content-center">
                <PostContent
                  variants={{
                    rest: { backgroundColor: 'transparent', },
                    hover: {
                      boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)',
                      backgroundColor: '#ffffff',
                      transition: {
                        duration: 0.15,
                        type: 'spring'
                      }
                    }
                  }}
                >
                  <div className="pt-3">
                    <h2 className="font-weight-xl font-size-ms">{parse(singleCase.title)}</h2>
                    <Description>
                      {parse(singleCase.acf.preview.description)}
                    </Description>
                  </div>
                  <div className="d-flex pt-2 justify-content-end">
                    <ButtonMinimal to={singleCase.path} icon="arrow">
                      <p>Lees verder</p>
                    </ButtonMinimal>
                  </div>
                </PostContent>
              </PostWrapper>
            </InnerPost>
          </Post>
        ))}
      </div>
    </StyledCases>
  )
}

export default AllVacatures